<template>
  <file-types-form></file-types-form>
</template>

<script>
import FileTypesForm from "../../components/forms/FileTypesForm";
export default {
  name: "AddFileType",
  components: {FileTypesForm}
}
</script>

<style scoped>

</style>