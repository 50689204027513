<template>
  <div>
    <!-- dataset breadcrumb starts -->
    <section class="breadcrumb_top mt-5">
      <div class="container">
        <div class="my_breadcrumb">
          <div class="right_top mb-4">
            <div class="card shadow">
              <div class="card-body">
                <div
                  class="company_footer d-flex justify-content-between align-items-center"
                >
                  <div class="header-title">
                    <h4 class="fw-bold text-dark">Timeseries Data</h4>
                  </div>

                  <div class="Filter_btn">
                    <button
                      v-if="$store.state.auth.isStaff"
                      class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#manageTimeSeriesModal"
                      @click="getSeriesData = true"
                    >
                      <span class="d-flex align-items-center">
                        <span class="small fw-bold">Manage TimeSeries</span>
                      </span>
                    </button>
                    <button
                      v-if="$store.state.auth.isStaff"
                      class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#addTimeSeriesModal"
                      @click="resetFields"
                    >
                      <span class="d-flex align-items-center">
                        <span class="small fw-bold">Upload TimeSeries</span>
                      </span>
                    </button>
                    <button
                      :disabled="downloading"
                      v-if="$store.state.auth.isStaff"
                      class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn"
                      @click="downloadMappingData"
                    >
                      <span class="d-flex align-items-center">
                        <span
                          v-if="downloading"
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="small fw-bold">Download Mapping Data</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <manage-time-series-modal
      :getSeriesData="getSeriesData"
      @itemUpdated="
        getData();
        getSeriesData = false;
      "
    ></manage-time-series-modal>
    <add-time-series-modal @added="getData"></add-time-series-modal>

    <!-- explore layer css -->
    <section>
      <div class="container">
        <div class="explore_inner_layer">
          <div class="row">
            <div class="col-md-3">
              <div class="card shadow">
                <div class="card-body">
                  <div class="company_footer">
                    <div class="form-group pt-3">
                      <label class="control-label fw-bolder text-dark fs-6"
                        >View Mode
                      </label>
                      <ul
                        class="nav nav-pills nav-fill"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active py-0"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            @click="fetchData(999999999, 'map')"
                          >
                            Map
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link py-0"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            @click="fetchData(10, '')"
                          >
                            Table
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link py-0"
                            id="chart-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#chart1"
                            type="button"
                            role="tab"
                            aria-controls="table1"
                            aria-selected="false"
                            @click="fetchData(999999999, 'chart')"
                          >
                            Chart
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div class="timeseries_filter mt-3">
                      <div class="form-group">
                        <label class="control-label fw-bolder text-dark fs-6"
                          >Date Range
                        </label>
                        <div class="d-flex flex-column">
                          From:
                          <input
                            type="date"
                            class="form-control"
                            v-model="selection.date.from"
                            @change="getData"
                          />
                        </div>
                        <div class="d-flex flex-column">
                          To:
                          <input
                            :min="selection.date.from"
                            type="date"
                            class="form-control"
                            v-model="selection.date.to"
                            @change="getData"
                          />
                        </div>
                      </div>

                      <div class="form-group pt-3">
                        <label class="control-label fw-bolder text-dark fs-6"
                          >Variables
                        </label>
                        <select
                          class="form-select form-control"
                          v-model="selection.variable"
                          @change="getData"
                        >
                          <option value="null" disabled>Select Variable</option>
                          <option
                            v-for="(item, index) in options.variables"
                            :key="index"
                            :value="item.variable"
                          >
                            {{ item.variable }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group pt-3">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="resetTimeseries"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <div class="timeseries_right">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-12">
                      <div class="timeseries_img">
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <vl-map
                              ref="map"
                              data-projection="EPSG:4326"
                              style="height: 600px"
                              :load-tiles-while-animating="true"
                              :load-tiles-while-interacting="true"
                            >
                              <vl-view
                                :zoom.sync="map.zoom"
                                :center.sync="map.center"
                                :rotation.sync="map.rotation"
                              ></vl-view>

                              <vl-layer-tile>
                                <vl-source-osm
                                  :attributions="[]"
                                ></vl-source-osm>
                              </vl-layer-tile>

                              <!-- <vl-interaction-select :features.sync="selected">
                                <vl-overlay
                                  class="feature-popup"
                                  v-for="feature in selected"
                                  :key="feature.id"
                                  :id="feature.id"
                                  :position="pointOnSurface(feature.geometry)"
                                  :auto-pan="true"
                                  :auto-pan-animation="{ duration: 300 }"
                                >
                                  <template slot-scope="">
                                    <section
                                      class="card"
                                      style="height: 10rem; overflow-y: scroll"
                                    >
                                      <div class="card-content">
                                        <div class="content p-2">
                                          <div
                                            class="h5 mt-2 pb-2 border-bottom"
                                          >
                                            Latest Measurement
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Location</strong>:
                                            {{
                                              feature.properties.location
                                                .Localname
                                            }},
                                            {{
                                              feature.properties.location
                                                .Municipality
                                            }}
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Source Type</strong>:
                                            {{
                                              feature.properties.Sourcetype
                                                ? feature.properties.Sourcetype
                                                : "N/A"
                                            }}
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Measured On</strong>:
                                            {{
                                              feature.properties.date
                                                | formatDateWithMonth
                                            }}
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Variable</strong>:
                                            {{
                                              feature.properties.variable.Name
                                                ? feature.properties.variable
                                                    .Name
                                                : feature.properties.variable.variable.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                            }}
                                            <span class="text-lowercase">
                                              {{
                                                feature.properties.variable.Unit
                                              }}</span
                                            >
                                          </div>
                                          <div class="fa-sm">
                                            <strong>Value:</strong>
                                            {{ feature.properties.value }}
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </template>
                                </vl-overlay>
                              </vl-interaction-select> -->

                              <!--  <template v-if="timeSeries.length > 0">
                                <vl-feature
                                  v-for="item in timeSeries"
                                  :key="item.id"
                                  :id="item.id"
                                  :properties="item"
                                >
                                  <vl-geom-point
                                    :coordinates="[
                                      item.location.longitude,
                                      item.location.latitude,
                                    ]"
                                  ></vl-geom-point>
                                  <vl-style>
                                    <vl-style-circle :radius="10">
                                      <vl-style-fill
                                        color="red"
                                      ></vl-style-fill>
                                      <vl-style-stroke
                                        color="white"
                                      ></vl-style-stroke>
                                    </vl-style-circle>
                                  </vl-style>
                                </vl-feature>
                              </template> -->
                            </vl-map>
                            <div id="popup">
                              <div id="popup-content"></div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div class="watersupplytable">
                              <div
                                class="table_card d-flex justify-content-end mb-3"
                              >
                                <div class="py-2 px-4">
                                  <button
                                    :disabled="downloadingseriesdata"
                                    v-if="$store.state.auth.isStaff"
                                    class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn"
                                    @click="downloadTimeseriesData"
                                  >
                                    <span class="d-flex align-items-center">
                                      <span
                                        v-if="downloadingseriesdata"
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="small fw-bold"
                                        >Download</span
                                      >
                                    </span>
                                  </button>
                                </div>
                                <div class="table_search">
                                  <!--                                  <div class="input-group">-->
                                  <!--                                    <div class="input-group-prepend">-->
                                  <!--                                      <span class="input-group-text" id="search"> <i class="fas fa-search"></i> </span>-->
                                  <!--                                    </div>-->
                                  <!--                                    <input-->
                                  <!--                                        type="text" class="form-control f-13" placeholder="Search now" aria-label="search"-->
                                  <!--                                        aria-describedby="search">-->
                                  <!--                                  </div>-->
                                </div>
                              </div>
                              <table
                                class="table table-success table-striped table-hover table-bordered"
                                v-if="timeSeries.length > 0"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Source type</th>
                                    <th scope="col">Measured On</th>
                                    <th scope="col">Variable</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">Measured By</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, index) in timeSeries"
                                    :key="item.id"
                                  >
                                    <th scope="row" v-if="meta.currentPage > 1">
                                      <p v-if="index !== 9">
                                        {{ meta.currentPage - 1 }}{{ ++index }}
                                      </p>
                                      <p v-else>
                                        {{ ++index * meta.currentPage }}
                                      </p>
                                    </th>
                                    <th v-else scope="row">
                                      {{ ++index }}
                                    </th>

                                    <td>
                                      {{ item.location.Localname }},
                                      {{ item.location.Municipality }}
                                    </td>
                                    <td>
                                      {{
                                        item.Sourcetype
                                          ? item.Sourcetype
                                          : "N/A"
                                      }}
                                    </td>
                                    <td>{{ item.date }}</td>
                                    <td>
                                      {{
                                        item.variable.Name
                                          ? item.variable.Name
                                          : item.variable.variable.replaceAll(
                                              "_",
                                              " "
                                            )
                                      }}
                                      {{ item.variable.Unit }}
                                    </td>
                                    <td>
                                      {{ item.value }}
                                    </td>
                                    <td>
                                      {{
                                        item.measured_by
                                          ? item.measured_by
                                          : "N/A"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div class="container pb-3 ps-5" v-else>
                                <h5>No records Found</h5>
                              </div>

                              <div class="container d-flex px-2">
                                <nav>
                                  <ul
                                    class="pagination"
                                    v-if="meta.totalPages > 1"
                                  >
                                    <li
                                      class="page-item"
                                      v-if="
                                        meta.currentPage !== 1 &&
                                        meta.totalPages > 1
                                      "
                                    >
                                      <button
                                        class="page-link"
                                        @click.prevent="
                                          getData(--meta.currentPage)
                                        "
                                        :disabled="meta.currentPage === 1"
                                      >
                                        Previous
                                      </button>
                                    </li>
                                    <li
                                      v-for="i in meta.totalPages"
                                      class="page-item"
                                      :class="{
                                        active: meta.currentPage === i,
                                      }"
                                      :key="i"
                                    >
                                      <a
                                        v-if="i < 5"
                                        class="page-link"
                                        href="#"
                                        @click.prevent="getData(i)"
                                        >{{ i }}</a
                                      >
                                    </li>
                                    <li
                                      class="page-item"
                                      :class="{
                                        active: ![0, 1, 2, 3, 4].includes(
                                          meta.currentPage
                                        ),
                                      }"
                                    >
                                      <a
                                        href="#"
                                        @click.prevent=""
                                        class="page-link"
                                        >...</a
                                      >
                                    </li>

                                    <li
                                      class="page-item"
                                      v-if="
                                        meta.currentPage !== meta.totalPages &&
                                        meta.totalPages > 1
                                      "
                                    >
                                      <button
                                        class="page-link"
                                        @click.prevent="
                                          getData(++meta.currentPage)
                                        "
                                        :disabled="
                                          meta.currentPage >= meta.totalPages
                                        "
                                      >
                                        Next
                                      </button>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="chart1"
                            role="tabpanel"
                            aria-labelledby="table1-tab"
                          >
                            <highcharts
                              v-if="chartOptions"
                              :constructorType="'stockChart'"
                              :options="chartOptions"
                            ></highcharts>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { findPointOnSurface } from "vuelayers/dist/ol-ext";
import AddTimeSeriesModal from "../../components/modals/AddTimeSeriesModal";
import ManageTimeSeriesModal from "../../components/modals/ManageTimeSeriesModal";
import download from "downloadjs";
import { fromLonLat } from "ol/proj";
import Vector from "ol/layer/Vector";
import SVector from "ol/source/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Fill from "ol/style/Fill";
//import Select from "ol/interaction/Select";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Overlay from "ol/Overlay";

export default {
  name: "TimeSeries",
  components: { AddTimeSeriesModal, ManageTimeSeriesModal },
  data() {
    return {
      downloading: false,
      downloadingseriesdata: false,
      loading: true,
      timeSeries: [],
      selected: [],
      meta: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 999999999,
      },
      map: {
        zoom: 11,
        center: [85.38982339512815, 27.68673136138436],
        rotation: 0,
      },
      selection: {
        variable: "null",
        date: {
          from: "null",
          to: "null",
        },
      },
      options: {
        variables: [],
      },
      chartOptions: null,
      minDate: "null",
      dataType: "map",
      getSeriesData: false,
    };
  },
  mounted() {
    this.getSelectionData();
    this.getData();
  },
  methods: {
    fetchData(pageSize, type) {
      this.dataType = type;
      this.meta.pageSize = pageSize;
      this.timeSeries = [];
      this.getData();
    },
    async downloadMappingData() {
      this.downloading = true;
      await this.$repository.location
        .get()
        .then((res) => {
          let data = res.data.results;

          let temp = [];

          data.forEach((el) => {
            let obj = {};

            if (el.variable.length > 0) {
              el.variable.forEach((v) => {
                obj = {
                  Municipality: el.Municipality,
                  "Location Id": el.location_id,
                  "Location Name": el.Localname,
                  "Variable Id": v.variable_id,
                  "Variable Name": v.variable,
                };
                temp.push(obj);
              });
            } else {
              obj = {
                Municipality: el.Municipality,
                "Location Id": el.location_id,
                "Location Name": el.Localname,
                "Variable Id": "N/A",
                "Variable Name": "N/A",
              };
              temp.push(obj);
            }
          });

          let csv = this.$papa.unparse({
            fields: [
              "Municipality",
              "Location Id",
              "Location Name",
              "Variable Id",
              "Variable Name",
            ],
            data: temp,
          });

          download(csv, "mappingData.csv");
          this.downloading = false;
        })
        .catch((err) => {
          this.downloading = false;
          console.log(err);
        });
    },
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    async downloadTimeseriesData() {
      this.downloadingseriesdata = true;
      await this.$repository.timeSeries
        .all()
        .then((res) => {
          let timeSeries = res.data.results;

          let temp = timeSeries.map((el) => {
            return {
              Address: `${el.location.Localname},${el.location.Municipality}`,
              "Source Type": el.Sourcetype,
              "Measured On": el.date,
              Variable: el.variable.Name
                ? el.variable.Name
                : el.variable.variable.replaceAll("_", " "),
              Value: el.value,
              "Measured By": el.measured_by ? el.measured_by : "N/A",
            };
          });
          let csv = this.$papa.unparse({
            fields: [
              "Address",
              "Source Type",
              "Measured On",
              "Variable",
              "Value",
              "Measured By",
            ],
            data: temp,
          });

          download(csv, "TimeseriesData.csv");
          this.downloadingseriesdata = false;
        })
        .catch((err) => {
          this.downloadingseriesdata = false;
          console.log(err);
        });
    },
    resetFields() {
      document.getElementById("resetfield").click();
    },
    resetTimeseries() {
      this.selection.variable = "null";
      this.selection.date.from = "null";
      this.selection.date.to = "null";
      this.chartVariable = "all variables";
      this.getData();
    },

    async getSelectionData() {
      await this.$repository.variables.all().then((res) => {
        this.options.variables = res.data.results;
      });
    },
    async getData(page = 1) {
      this.meta.currentPage = page;

      let params = `?page_size=${this.meta.pageSize}&ordering=-uploaded_at`;
      if (this.meta.currentPage > 1) {
        params += `&page=${this.meta.currentPage}`;
      }

      if (this.selection.variable !== "null") {
        params += `&variable=${this.selection.variable}`;
        this.chartVariable = this.selection.variable;
      }

      if (this.selection.date.from !== "null") {
        params += `&date__gte=${this.selection.date.from}`;
      }
      if (this.selection.date.to !== "null") {
        params += `&date__lte=${this.selection.date.to}`;
      }

      await this.$repository.timeSeries.filter(params).then((res) => {
        this.timeSeries = res.data.results;
        if (this.dataType == "map") {
          const popupContent = document.getElementById("popup-content");
          if (popupContent) {
            popupContent.innerHTML = "";
          }
          if (this.timeSeries.length == 0) {
            const layers = [...this.$refs.map.$map.getLayers().getArray()];
            layers.forEach((layer) => {
              if (layer && layer.get("name") === "Marker") {
                this.$refs.map.$map.removeLayer(layer);
              }
            });
          } else {
            setTimeout(() => {
              this.timeSeries.forEach((el) => {
                var layer = new Vector({
                  source: new SVector({
                    features: [
                      new Feature({
                        geometry: new Point(
                          fromLonLat([
                            el.location.longitude,
                            el.location.latitude,
                          ])
                        ),
                        property: {
                          Sourcetype: el.Sourcetype,
                          date: el.date,
                          id: el.id,
                          location: el.location,
                          measured_by: el.measured_by,
                          uploaded_at: el.uploaded_at,
                          value: el.value,
                          variable: el.variable,
                        },
                      }),
                    ],
                  }),
                  name: "Marker",
                  style: new Style({
                    image: new Circle({
                      radius: 10,
                      stroke: new Stroke({
                        color: "white",
                        width: 2,
                      }),
                      fill: new Fill({
                        color: "red",
                      }),
                    }),
                  }),
                });
                /*               var select = new Select({
                layers: [layer],
              });

              this.$refs.map.$map.addInteraction(select); */
                this.$refs.map.$map.addLayer(layer);
              });

              const popup = new Overlay({
                element: document.getElementById("popup"),
              });
              this.$refs.map.$map.addOverlay(popup);
              this.$refs.map.$map.on("singleclick", (evt) => {
                var feature = this.$refs.map.$map.forEachFeatureAtPixel(
                  evt.pixel,
                  function (feature) {
                    return feature;
                  }
                );

                if (feature) {
                  const coordinate = evt.coordinate;
                  const property = feature.values_.property;
                  property.date = moment(String(property.date)).format(
                    "DD MMMM, YYYY"
                  );
                  /* let list = document.createElement("ul");
                list.style.padding = "10px";
                list.style.listStyle = "none";

                for (const key in property) {
                  if (Object.hasOwnProperty.call(property, key)) {
                    const element = property[key];
                    let listItem = document.createElement("li");
                    listItem.innerHTML = `<strong> ${key}:</strong> ${element}`;
                    list.appendChild(listItem);
                  }
                } */
                  let content = `<section
                                      class="card"
                                      style="height: 10rem; overflow-y: scroll"
                                    >
                                      <div class="card-content">
                                        <div class="content p-2">
                                          <div
                                            class="h5 mt-2 pb-2 border-bottom"
                                          >
                                            Latest Measurement
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Location</strong>:
                                           ${property.location.Localname},
                                            ${property.location.Municipality}
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Source Type</strong>:
                                          ${
                                            property.Sourcetype
                                              ? property.Sourcetype
                                              : "N/A"
                                          }
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Measured On</strong>:
                                            ${property.date}
                                          </div>
                                          <div class="fa-sm text-capitalize">
                                            <strong>Variable</strong>:
                                           ${
                                             property.variable.Name
                                               ? property.variable.Name
                                               : property.variable.variable.replaceAll(
                                                   "_",
                                                   " "
                                                 )
                                           }
                                            <span class="text-lowercase">
                                             ${property.variable.Unit}</span
                                            >
                                          </div>
                                          <div class="fa-sm">
                                            <strong>Value:</strong>
                                            ${property.value}
                                          </div>
                                        </div>
                                      </div>
                                    </section>`;

                  const popupContent = document.getElementById("popup-content");
                  popupContent.innerHTML = content;

                  popup.setPosition(coordinate);
                } else {
                  popup.setPosition(undefined);
                }
              });
            }, 1000);
          }
        }
        this.meta.totalItems = res.data.count;
        this.meta.totalPages = Math.ceil(res.data.count / 10);

        if (this.dataType == "chart") {
          let arr = [];
          this.timeSeries.forEach((el) => {
            arr.push([new Date(el.date).getTime(), el.value]);
          });

          if (!this.chartVariable) {
            this.chartVariable = "all variables";
          }

          if (arr.length) {
            arr.sort(this.dynamicSort([0]));
          }

          this.chartOptions = {
            title: {
              text: `Time series line graph for ${this.chartVariable}`,
            },
            series: [
              {
                data: arr,
              },
            ],
            yAxis: {
              title: {
                text: "Value",
              },
            },
            rangeSelector: {
              selected: 1,
            },
            xAxis: {
              title: {
                text: "Measured On",
              },
              type: "date",
            },
            credits: {
              enabled: false,
            },
          };
        }

        this.loading = false;
      });
    },
    pointOnSurface: findPointOnSurface,
  },
};
</script>
<style scoped lang="scss">
table thead tr {
  th {
    width: 15%;
  }
  th:nth-child(1) {
    width: 2%;
  }
  th:nth-child(2) {
    width: 23%;
  }
}
</style>
