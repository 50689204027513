import { render, staticRenderFns } from "./VariablesForm.vue?vue&type=template&id=cb754eda&scoped=true&"
import script from "./VariablesForm.vue?vue&type=script&lang=js&"
export * from "./VariablesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb754eda",
  null
  
)

export default component.exports