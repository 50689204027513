<template>
  <div
    class="modal fade"
    id="manageTimeSeriesModal"
    tabindex="-1"
    aria-labelledby="manageTimeSeriesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="manageTimeSeriesModalLabel">
            Timeseries List
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body px-4">
          <div class="collapse mb-4 border p-4" id="editTimeSeries">
            <timeseries-edit-form
              :item="item"
              @updated="itemUpdated"
              @cancel="cancelUpdate"
            ></timeseries-edit-form>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th>S/N</th>
                <th>ID</th>
                <th>Value</th>
                <th>Variables</th>
                <th>Source Type</th>
                <th>Date</th>
                <th>Measured By</th>
                <th>Location</th>
                <th>Uploaded At</th>
                <th width="12%" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in timeseries" :key="item.id">
                <th scope="row" v-if="meta.currentPage > 1">
                  <p v-if="index !== 9">
                    {{ meta.currentPage - 1 }}{{ ++index }}
                  </p>
                  <p v-else>
                    {{ ++index * meta.currentPage }}
                  </p>
                </th>
                <th v-else scope="row">
                  {{ ++index }}
                </th>
                <td>{{ item.id }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.variable.Name }}</td>
                <td>{{ item.Sourcetype }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.measured_by }}</td>
                <td>{{ item.location.Localname }}</td>
                <td>{{ item.uploaded_at }}</td>

                <td>
                  <button
                    class="btn btn-primary btn-sm me-3"
                    @click.prevent="editTimeSeries(item)"
                    :disabled="isUpdating"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    :disabled="isUpdating"
                    @click.prevent="deleteTimeSeries(item.id)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                  >
                    <i class="bi bi-trash-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <nav>
            <ul class="pagination" v-if="meta.totalPages > 1">
              <li
                class="page-item"
                v-if="meta.currentPage !== 1 && meta.totalPages > 1"
              >
                <button
                  class="page-link"
                  @click.prevent="getData(--meta.currentPage)"
                  :disabled="meta.currentPage === 1"
                >
                  Previous
                </button>
              </li>
              <li
                v-for="i in meta.totalPages"
                class="page-item"
                :class="{ active: meta.currentPage === i }"
                :key="i"
              >
                <a class="page-link" href="#" @click.prevent="getData(i)">{{
                  i
                }}</a>
              </li>
              <li
                class="page-item"
                v-if="
                  meta.currentPage !== meta.totalPages && meta.totalPages > 1
                "
              >
                <button
                  class="page-link"
                  @click.prevent="getData(++meta.currentPage)"
                  :disabled="meta.currentPage >= meta.totalPages"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeseriesEditForm from "../../components/forms/TimeseriesEditForm.vue";
export default {
  name: "ManageTimeSeriesModal",
  components: { TimeseriesEditForm },
  props: ["getSeriesData"],
  data() {
    return {
      editCollapse: null,
      isUpdating: false,
      timeseries: [],
      meta: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
      },
      item: null,
    };
  },
  /*   mounted() {
    this.getData();
  }, */

  watch: {
    getSeriesData: {
      handler(newVal) {
        if (newVal) {
          this.getData();
        }
      },
    },
  },
  methods: {
    collapseEditForm(type) {
      if (!this.editCollapse) {
        this.editCollapse = new this.$bootstrap.Collapse(
          document.getElementById("editTimeSeries")
        );
      }
      if (type === "show") {
        this.editCollapse.show();
      } else {
        this.editCollapse.hide();
      }
    },
    collapseEditTimeSeriesForm() {
      this.collapseEditForm("hide");
      this.item = null;
      this.isUpdating = false;
    },
    itemUpdated() {
      this.collapseEditTimeSeriesForm();
      this.getData();
      this.$emit("itemUpdated");
    },
    cancelUpdate() {
      this.collapseEditTimeSeriesForm();
    },
    async deleteTimeSeries(id) {
      await this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$repository.timeSeries
              .delete(id)
              .then(() => {
                this.getData();
                this.$emit("itemUpdated");
                this.$toast.success("TimeSeries deleted");
              })
              .catch(() => {
                this.$toast.error("Deletion failed");
              });
          }
        },
      });
    },
    editTimeSeries(item) {
      this.isUpdating = true;
      this.item = item;
      this.collapseEditForm("show");
    },
    async getData(page = 1) {
      this.meta.currentPage = page;

      let params = `?page_size=${this.meta.pageSize}&ordering=-uploaded_at`;

      if (this.meta.currentPage > 1) {
        params += `&page=${this.meta.currentPage}`;
      }

      await this.$repository.timeSeries.filter(params).then((res) => {
        this.timeseries = res.data.results;
        this.meta.totalItems = res.data.count;
        this.meta.totalPages = Math.ceil(res.data.count / 10);
      });
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 67%;
  overflow: initial !important;
}
.modal-body {
  height: 80vh;
  overflow-y: auto;
}
</style>
