<template>
  <div class="mx-3 timeseriesform">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <div class="row">
          <div class="col-8 mb-3">
            <validation-provider
              v-slot="{ errors, pristine, validate }"
              name="File"
              rules="required"
            >
              <label for="value" class="form-label"
                >File (csv) <span class="text-danger text-sm ">*</span></label
              >
              <input
                ref="csvfile"
                type="file"
                accept=".csv,text/csv"
                class="form-control"
                :class="{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                }"
                id="file"
                @input="validate"
                placeholder="Select CSV file"
                @change="processFile"
              />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
          <div
            class="col-md-4 mb-3 d-flex align-items-center justify-content-end"
          >
            <a
              v-if="$store.state.auth.isLoggedIn"
              class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn mt-2"
              target="_blank"
              download="sample.csv"
              href="/data/sample.csv"
            >
              <span
                class="d-flex align-items-center justify-content-between mx-2"
                ><span class="small fw-bold">Download Template</span
                ><i class="fas fa-download me-2"></i
              ></span>
            </a>
          </div>
        </div>
        <div style="width:150px" class="d-flex justify-content-between">
          <button
            type="submit"
            :disabled="saving"
            class="btn btn-primary px-2 btn-sm "
          >
            <span
              v-if="saving"
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Save
          </button>
          <button
            @click="resetFields"
            type="button"
            id="resetfield"
            class="btn btn-danger px-2 btn-sm"
          >
            Reset
          </button>
        </div>
      </form>
    </validation-observer>
    <div v-if="errRows.length" class="py-2">
      <span class="text-danger mr-3">Note:</span>
      <span class="text-secondary">
        (Error data are highlighted with red colour)
      </span>
      <br />
      <span class="text-danger mr-3">Error Found:</span>
      <span class="text-secondary"
        >Hover over errorneous row for errorMessage</span
      >
    </div>
    <div v-if="parsedData.length !== 0" class="mt-3 pb-4">
      <table class="table table-hover table-bordered">
        <thead class="bg-secondary">
          <tr v-if="headers">
            <th scope="col" v-for="(colName, i) in headers" :key="i">
              <span> {{ colName }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, rowIndex) in parsedData"
            :key="rowIndex"
            data-toggle="tooltip"
            data-placement="top"
            :title="errRows[rowIndex]"
          >
            <td
              :class="failing_row.includes(rowIndex) ? 'text-danger' : ''"
              scope="row"
              v-for="(item, colIndex) in data"
              :key="colIndex"
            >
              <span> {{ item }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="csvFormatError">
      {{ csvFormatError }}
      <a
        class="text-decoration-underline"
        download="sample.csv"
        href="/data/sample.csv"
        >here</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSeriesForm",
  data() {
    return {
      saving: false,
      isParsed: false,
      uploading: false,
      form: {
        file: null,
      },
      csvFormatError: "",
      parsedData: [],
      headers: [],
      initialData: [],
      errRows: [],
      failing_row: [],
    };
  },
  methods: {
    processFile() {
      this.failing_row = [];
      this.errRows = [];
      this.form.file = this.$refs.csvfile.files[0];
      this.$papa.parse(this.form.file, {
        download: true,
        complete: async (results) => {
          this.initialData = await results.data;
          /* check if column name is specified */
          if (
            this.initialData[0][0] === "id" &&
            this.initialData[0][1] === "variable"
          ) {
            this.parsedData = this.initialData.slice(); // then only render data
            this.parsedData.shift();
            /* setting table headers */
            this.headers = this.initialData[0];
          } else {
            // this.$toast.error("Invalid CSV format");
            this.csvFormatError = `Please specify valid column name or dowload template from`;
          }
        },
      });
    },
    save() {
      /* initialData */
      let data = new FormData();
      data.append("CSV", this.form.file);
      this.saving = true;
      this.$repository.timeSeries
        .uploadCSV(data)
        .then(() => {
          this.resetFields();
          this.$emit("created");
          this.$toast.success("CSV file successfully uploaded");
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          if (err.response.status === 404) {
            let error = err.response.data.Error;

            /* get only failing row data */
            this.failing_row = error.map((el) => {
              return el.failing_row_no;
            });

            let temp = [];
            error.forEach((err_row) => {
              let message = "";
              err_row.message.forEach((msg) => {
                message = message + `${msg[0]}:${msg[1][0]},`;
              });

              temp[err_row.failing_row_no] = message;
            });

            this.errRows = temp;
          }
        });
    },

    resetFields() {
      this.$refs.csvfile.value = null;
      this.form.file = null;
      this.csvRes = null;
      this.csvFormatError = "";
      this.parsedData = [];
      this.initialData = [];
      this.headers = [];
      this.errRows = [];
      this.failing_row = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.timeseriesform {
  height: 90%;
}
</style>
