<template>
  <variables-form></variables-form>
</template>

<script>
import VariablesForm from "../../components/forms/VariablesForm";
export default {
  name: "AddVariables",
  components: {VariablesForm}
}
</script>

<style scoped>

</style>