var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"variable id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"variable_id"}},[_vm._v("Variable ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.variable_id),expression:"form.variable_id"}],staticClass:"form-control",class:{ 'is-invalid' : errors[0], 'is-valid' : (!errors[0] && !pristine) },attrs:{"type":"text","id":"variable_id","placeholder":"Variable ID"},domProps:{"value":(_vm.form.variable_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "variable_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"variable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"variable"}},[_vm._v("Variable")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.variable),expression:"form.variable"}],staticClass:"form-control",class:{ 'is-invalid' : errors[0], 'is-valid' : (!errors[0] && !pristine) },attrs:{"type":"text","id":"variable","placeholder":"Variable"},domProps:{"value":(_vm.form.variable)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "variable", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Name),expression:"form.Name"}],staticClass:"form-control",class:{ 'is-invalid' : errors[0], 'is-valid' : (!errors[0] && !pristine) },attrs:{"type":"text","id":"name","placeholder":"Name"},domProps:{"value":(_vm.form.Name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Description),expression:"form.Description"}],staticClass:"form-control",class:{ 'is-invalid' : errors[0], 'is-valid' : (!errors[0] && !pristine) },attrs:{"id":"description","placeholder":"Description"},domProps:{"value":(_vm.form.Description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"unit","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"unit"}},[_vm._v("Unit")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Unit),expression:"form.Unit"}],staticClass:"form-control",class:{ 'is-invalid' : errors[0], 'is-valid' : (!errors[0] && !pristine) },attrs:{"type":"text","id":"unit","placeholder":"Unit"},domProps:{"value":(_vm.form.Unit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Unit", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('button',{staticClass:"btn btn-primary px-5",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }